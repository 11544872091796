export const environment = {
    production: false,
    urls: {
        api: 'https://api.n2g-iona.net/v2/',
        auth: 'https://iona-test-live.wla-backend.de/auth',
        wmsb: 'https://ews.n2g-iona.net/api',
        faq: 'https://iona-dev.wla-backend.de/faq/esc?showNav=true',
        rewriteUrl: '',
        userGroup: 'https://ews.n2g-iona.net/api',
    },
    isStaging: false,
};


